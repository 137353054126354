import { message } from "antd";

const GlobalMessage = {
  loading: (content, duration) => {
    message.loading(content, duration);
  },
  success: (content, duration) => {
    message.success(content, duration);
  },
  error: (content, duration) => {
    message.error(content, duration);
  },
  info: (content, duration) => {
    message.info(content, duration);
  },
  warning: (content, duration) => {
    message.warning(content, duration);
  },
  open: ({ key, type, content }) => {
    message?.open({ key, type, content });
  },
};

export default GlobalMessage;
