import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, theme } from 'antd';
import Loader from 'components/Loader';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>


  <Suspense fallback={<Loader />}>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider

        // color palette for the app is #000000, #3D0000, #950101 and #FF0000
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: '#FF0000',
            borderRadius: 5,
            colorLink: '#FF0000',
            margin: 0,
            padding: '8px',
            fontSize: 14,
            sizeXXS: 4,
            sizeXS: 8,
          }
        }}
      >
        <App />
      </ConfigProvider>

    </QueryClientProvider>
  </Suspense >
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
