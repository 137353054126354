import axios from "axios";
import GlobalMessage from "components/GlobalMessage";
import Cookies from "js-cookie";

const userLogout = () => {
  Cookies.remove("scraperToken");
  Cookies.remove("username");
  window.location.href = "/";
};

const token = Cookies.get("scraperToken");

const request = async ({
  TOKEN,
  BASE_URL,
  isUser,
  successMessage,
  ...options
}) => {
  const userID = Cookies.get("username");

  const client = axios.create({
    baseURL: BASE_URL,
  });
  client.defaults.headers.common.Authorization = `Bearer ${
    token ? token : TOKEN
  }`;
  if (!isUser) {
    client.defaults.headers.common.User = userID;
  }
  const onSuccess = (response) => {
    if (response.status) {
      if (successMessage) {
        GlobalMessage.success(response?.data?.message || response?.message);
      }
      return response?.data;
    }
    return response?.data;
  };
  const onError = (error) => {
    if (error.response.status === 401) {
      userLogout();
      return error;
    }
    return error;
  };
  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
export default request;
