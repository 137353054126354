import { Button, Layout } from "antd";
import React from "react";
import { BugFilled } from "@ant-design/icons";
import TablerIcon from "components/TablerIcon";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const { Header: AntdHeader } = Layout;

const Header = () => {
  const { IconPower } = TablerIcon;
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("scraperToken");
    localStorage.clear();
    sessionStorage.clear();
    navigate(`/login`);
  };

  return (
    <AntdHeader className="header_main">
      <div className="logo">
        <BugFilled />
      </div>
      <div className="action_section">
        <Button
          type="text"
          icon={<IconPower />}
          shape="circle"
          onClick={handleLogout}
        />
      </div>
    </AntdHeader>
  );
};

export default Header;
