import request from "./config";

// const userID = Cookies.get('username');
const BASE_URL = process.env.REACT_APP_API_BASE_LINK;

// Function for making GET requests
export const fetchApiRequest = async ({
  successMessage,
  endpoint,
  params = {},
}) => {
  const response = await request({
    BASE_URL,
    url: endpoint,
    method: "GET",
    successMessage,
    params,
  });
  return response;
};
// Function for making POST requests
export const alterApiRequest = async ({
  successMessage,
  endpoint,
  payload = {},
}) => {
  const axiosConfig = {
    BASE_URL,
    successMessage,
    method: "POST",
    url: endpoint,
    data: payload,
  };
  const response = await request(axiosConfig);
  return response;
};

// Function for making POST requests
export const putApiRequest = async ({ endpoint, payload = {} }) => {
  const axiosConfig = {
    method: "PUT",
    url: endpoint,
  };
  axiosConfig.data = payload;

  const response = await request(axiosConfig);
  return response;
};

// Function for making DELETE requests
export const removeApiRequest = async ({ endpoint }) => {
  const response = await request({
    BASE_URL,
    method: "DELETE",
    url: endpoint,
  });
  return response;
};
