import { Button, Checkbox, Input, Form } from "antd";
import React from "react";
import { BugFilled } from "@ant-design/icons";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import GlobalMessage from "components/GlobalMessage";
import { alterApiRequest } from "utility/apiConfig/apiRequest";
import useMutationHook from "hooks/useMutationHook";
import Loader from "components/Loader";
import { USER_LOGIN_API } from "utility/Apis";
// import { Form } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const successCallback = (response) => {
    debugger;
    if (!response.data.token) {
      GlobalMessage.error("Invalid username or password");
      return;
    }
    Cookies.set("scraperToken", response.data.token);
    navigate(`/dashboard`);
    window.location.reload();
  };

  const { mutate: updateFormMutation, isLoading } = useMutationHook({
    queryFn: (data) => {
      return alterApiRequest({
        endpoint: USER_LOGIN_API,
        payload: {
          username: data.username,
          password: data.password,
        },
        hasBody: true,
      });
    },
    showCustomMessage: true,
    queryKey: [USER_LOGIN_API],
    onSuccess: successCallback,
  });

  if (isLoading) {
    return <Loader />;
  }

  const onFinish = (values) => {
    updateFormMutation({
      username: values.username,
      password: values.password,
    });
  };

  return (
    <>
      <div className="login_container">
        <div className="login_wrapper">
          <div className="login_icon">
            <BugFilled />
          </div>
          <div className="title_text">
            <h1>Welcome Back!</h1>
            <p>Log in to your account to continue</p>
          </div>
          <Form
            name="basic"
            className="login_form"
            initialValues={{
              remember: true,
            }}
            requiredMark={false}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="User Name"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter your Username!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <div className="form_remember">
              <Form.Item
                className="remember"
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Button type="link">Forgot Password</Button>
            </div>
            <Form.Item className="login_button">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Log in
              </Button>
            </Form.Item>
            <div className="form_signup">
              <p>Don't have an account?</p>
              <Button type="link" disabled={isLoading}>
                Sign up now
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
