import { Layout, Menu } from "antd";
import React from "react";
import {
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  AimOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;

const SidebarLander = ({ setSideBarState }) => {
  const navigate = useNavigate();

  const items = [
    {
      key: "dashboard",
      icon: <DesktopOutlined />,
      label: "Dashboard",
    },
    {
      key: "domains",
      icon: <AimOutlined />,
      label: "Domains",
    },
    {
      key: "add-domain",
      icon: <ContainerOutlined />,
      label: "Add Domain",
    },
    {
      key: "sub1",
      label: "Navigation One",
      icon: <MailOutlined />,
      children: [
        {
          key: "5",
          label: "Option 5",
        },
        {
          key: "6",
          label: "Option 6",
        },
        {
          key: "7",
          label: "Option 7",
        },
        {
          key: "8",
          label: "Option 8",
        },
      ],
    },
  ];

  const handleClick = (e) => {
    navigate(`/${e.key}`);
  };

  const onCollapse = (collapsed) => {
    console.log(collapsed);
    debugger;
    setSideBarState(collapsed);
  };

  return (
    <div className="sidebar-lander">
      <Sider
        width={277}
        breakpoint="lg"
        collapsible
        onCollapse={onCollapse}
        collapsedWidth={50}
        zeroWidthTriggerStyle={{
          // background: "white",
          // color: "black",
          borderRadius: 50,
          top: 24,
          // inset-inline-end
          insetInlineEnd: -20,
          // height: 28,
          // width: 28,
          // box-shadow: 0px 3px 6px 0px #00000029;
          boxShadow: "0px 3px 6px 0px #00000029",
        }}
      >
        <Menu
          defaultSelectedKeys={[window.location.pathname?.substring(1)]}
          // defaultOpenKeys={['1']}
          // selectedKeys={[current]}
          items={items}
          mode="inline"
          // items={insertAfterEveryElement(items, { type: 'divider' })}
          onClick={(e) => handleClick(e)}
          className="sidebar-menu"
        />
      </Sider>
    </div>
  );
};

export default SidebarLander;
