import { useMutation } from "@tanstack/react-query";
import GlobalMessage from "components/GlobalMessage";


function useMutationHook({
  queryKey,
  queryFn,
  onSuccess,
  showCustomMessage = false,
}) {
  const customOnSuccess = (data) => {
    if (data?.statusCode <= 300 || data?.status || data?.data?.status) {
      GlobalMessage?.open({
        key: "manipulateData",
        type: "success",
        content: data?.message || data?.data?.message || "Operation Successful",
      });
      onSuccess(data);
    } else {
      GlobalMessage?.open({
        key: "manipulateData",
        type: "error",
        content:
          data?.response?.data?.message ||
          data?.response?.data?.error ||
          "An error occurred",
      });
    }
  };
  const customOnError = (err) => {
    GlobalMessage?.open({
      key: "manipulateData",
      type: "error",
      content: err?.response?.data?.error || "An error occurred",
    });
  };
  const { data, error, isError, isLoading, isSuccess, failureReason, mutate } =
    useMutation({
      mutationKey: queryKey,
      mutationFn: queryFn,
      onSuccess: showCustomMessage ? (e) => onSuccess(e) : customOnSuccess,
      onError: customOnError,
    });

  if (isLoading && !showCustomMessage) {
    // GlobalMessage.open({
    //   key: "manipulateData",
    //   type: "loading",
    //   content: "In Progress...",
    // });
  }

  return {
    mutate,
    error,
    data,
    isLoading,
    isSuccess,
    isError,
    failureReason,
  };
}

export default useMutationHook;

// use case
// import useMutationHook from './useMutationHook'; // Import your custom hook

// function MyComponent() {
//   const mutation = useMutationHook();

//   const handleMutation = () => {
//     mutation.mutate(async () => {
//       // Your mutation logic goes here
//       const result = await someAsyncFunction();
//       return result;
//     });
//   };

//   return <button onClick={handleMutation}>Invoke Mutation</button>;
// }

// const mutation = useMutation(yourMutationFunction);

// // Example usage
// if (mutation.isLoading) {
//   // Display a loading indicator
// } else if (mutation.isError) {
//   // Handle the error
//   console.error('Mutation error:', mutation.error);
// } else if (mutation.isSuccess) {
//   // Use the data returned by the mutation
//   console.log('Mutation data:', mutation.data);
// }

// // Trigger the mutation manually
// const handleMutation = () => {
//   mutation.mutate(); // Or mutation.mutateAsync() if you want to await it
// };
